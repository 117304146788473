<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label for="numeroAnuncio">Número do Anúncio</label>
          <tag-input id="numeroAnuncio" name="numeroAnuncio" [(ngModel)]='filtro.anuncioCodigos'
            [separatorKeyCodes]="separadores" [validators]="somenteNumero" placeholder="Número"
            secondaryPlaceholder="Filtrar por Número" addOnBlur="true" theme='bootstrap'></tag-input>
        </div>

        <div class="ui-g-12">
          <label for="anuncioSituacoes">Situação do Anúncio</label>
          <p-multiSelect id="anuncioSituacoes" name="anuncioSituacoes" defaultLabel="Todos"
            [(ngModel)]="filtro.anuncioSituacoesSelecionadas" [options]="filtro.anuncioSituacoes" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>

        <div class="ui-g-12">
          <label for="periodoInclusao">Período de Inclusão</label>
          <p-calendar id="periodoInclusao" name="periodoInclusao" [(ngModel)]="filtro.periodoInclusao"
            selectionMode="range" readonlyInput="true" [locale]="pt" dateFormat="dd/mm/yy" [showIcon]="true"
            #periodoInclusao>
            <p-footer>
              <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker">
                <p-button icon="pi pi-check" class="button-datapicker-ok" (onClick)="fecharCalendario(periodoInclusao)">
                </p-button>
                <p-button icon="pi pi-times" class="button-datapicker-cancel"
                  (onClick)="limparPeriodoInclusao(periodoInclusao)"></p-button>
              </div>
            </p-footer>
          </p-calendar>
        </div>

        <div class="ui-g-12">
          <label for="periodoAutorizacao">Período de Autorização</label>
          <p-calendar id="periodoAutorizacao" name="periodoAutorizacao" [(ngModel)]="filtro.periodoAutorizacaoDoacao"
            selectionMode="range" readonlyInput="true" [locale]="pt" dateFormat="dd/mm/yy" [showIcon]="true"
            #periodoAutorizacao>
            <p-footer>
              <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker">
                <p-button icon="pi pi-check" class="button-datapicker-ok"
                  (onClick)="fecharCalendario(periodoAutorizacao)">
                </p-button>
                <p-button icon="pi pi-times" class="button-datapicker-cancel"
                  (onClick)="limparPeriodoAutorizacao(periodoAutorizacao)"></p-button>
              </div>
            </p-footer>
          </p-calendar>
        </div>

        <div class="ui-g-12">
          <label for="anunciante">Anunciante</label>
          <p-multiSelect id="anunciante" name="anunciante" defaultLabel="Todos"
            [(ngModel)]="filtro.anuncianteTiposSelecionados" [options]="filtro.anuncianteTipos" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>

        <div class="ui-g-12">
          <label for="materialTipo">Tipo do Material / Serviço</label>
          <p-multiSelect id="materialTipo" name="materialTipo" defaultLabel="Todos"
            [(ngModel)]="filtro.materialTiposSelecionados" [options]="filtro.materialTipos" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>

        <div class="ui-g-12">
          <label for="materialCategoria">Categoria do Material / Serviço</label>
          <p-multiSelect id="materialCategoria" name="materialCategoria" defaultLabel="Todos"
            [(ngModel)]="filtro.materialCategoriasSelecionadas" [options]="filtro.materialCategorias" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>

        <div class="ui-g-12">
          <div for="orgao"><strong>Órgão do Donatário</strong></div>
          <p-dropdown #orgao id="orgao" [options]="listaOrgao" [autoWidth]="false" (onChange)="recuperarEntidades()"
            [(ngModel)]="filtro.orgao" [filter]="true" optionLabel="idNome" dataKey="id"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <div for="entidade"><strong>Entidade do Donatário</strong></div>
          <p-dropdown #entidade id="entidade" [options]="listaEntidade" [autoWidth]="false"
            [(ngModel)]="filtro.entidade" [filter]="true" optionLabel="nomeNumero" dataKey="id"
            [disabled]="listaEntidade.length == 0"></p-dropdown>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()">
        </p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="aplicarFiltros()">
        </p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>
