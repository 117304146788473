<div class="divMain">
  <div class="divCard br-card">
    <div class="item">
      Navegadores compatíveis:
      <div class="subitem">
        <i class="fa-brands fa-chrome"></i> Chrome 71+&nbsp;/&nbsp;
        <i class="fa-brands fa-edge"></i> Edge 41+&nbsp;/&nbsp;
        <i class="fa-brands fa-firefox"></i> Firefox 52+
      </div>
      <div class="subitem">Melhor visualizado em 1024x768</div>
    </div>

    <div class="item">
      Versão:
      <div class="subitem">F42c60143 - 27/08/2024 08:58</div>
      <div class="subitem" *ngIf="versaoBackend">{{versaoBackend.identificacao}} - {{versaoBackend.nomeBuild}} - {{versaoBackend.timestampBuild}}</div>
    </div>
    <div class="item">
      Copyright <i class="fa fa-copyright"></i> Todos os direitos reservados.
    </div>
  </div>
</div>
